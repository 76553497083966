.policymain{

}

.bgpolicy{
    height: 300px;
    width: 99%;
    background-color:#f55d2c;
}
.bgterm2{
    height: 300px;
    width: 99%;
    background-color:#f55d2c ;
}
.crdpolicy{
    height: 100%;
    width: 80%;
    margin-top: -12%;
    padding: 20px;
    box-shadow: 2px 2px 2px 2px #f55d2c;
    margin-bottom: 30px;
    /* position: relative; */
}
.btnpolicy{
background-color: #f55d2c;
border: none;
}
.btnpolicy:hover{
background-color: #f55d2c;
border: none;
}
@media screen and (max-width: 480px) {
    .crdpolicy{
        height: 100%;
        width: 90%;
        margin-top: -50%;
        
    }
  }