.catecard{
    height: 180px;
    width: 220px;
    background-color: #ffffff;
    margin: 10px;
    border-radius: 15px;
    padding: 20px;
    text-align: center;
}
.imgpro{
    height:80px;
    width: 60px;
}