.termmain {}

.bgterm1 {
    height: 300px;
    width: 99%;
    background-color: #f55d2c;
}

.bgterm2 {
    height: 300px;
    width: 99%;
    background-color: #9b9aa0;
}

.crdterm {
    height: 100%;
    width: 80%;
    margin-top: -15%;
    padding: 20px;
    box-shadow: 2px 2px 2px 2px #f55d2c;
    margin-bottom: 30px;
    /* position: relative; */
}

.btnterm {
    background-color: #f55d2c;
    border: none;
    margin-top: 20px;
}

.btnterm:hover {
    background-color: #f55d2c;
    border: none;
}

@media screen and (max-width: 480px) {
    .crdterm {
        height: 100%;
        width: 90%;
        margin-top: -50%;

    }
}