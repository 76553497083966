.containerStyleDownload {
    background: linear-gradient(180deg, #FFFEFC -54.51%, #FFF8F0 99.98%);
    border-radius: 30px;
    padding: 20px;
}

.downloadCol1 {
    margin-top: 150px
}

.downloadBtnImg {
    height: 50px;
    width: 150px;
    margin-top: 50px;
    margin-bottom: 20px;
}


@media screen and (max-width: 480px) {
    .downloadCol1 {
        margin-top:20px;
        text-align: center;
    }
    .downloadBtnImg {
        margin-top: 20px;
    }
}