.imgbest {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    margin-top: 20px;
}

.cashBack {
    margin-top: 50px;
    width: 100%;
    height: 90%;
    border-radius: 20px;
}

@media screen and (max-width: 480px) {
    .cashBack {
        width: 100%;
    }
}