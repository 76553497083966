.procard{
    padding: 20px;
    margin-top: 20px;
    width: 100%;
    border-radius: 20px;
    background-color: #ffffff;
}
.btnlike{
    border-radius: 50%;
    margin-top: -30px;
    background-color: #f7d5ca;
    border: none;
    color: #f55d2c;
}
.btnlike:hover{
    background-color: #f55d2c;
    border: none;
    color: #ffffff;
}
.imglike{
    height: 100%;
    width: 100%;
     transition: 1s;
}
.imglike:hover{
     transform: scale(1.2);
}