@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

h1{
    font-family: 'DM Sans', sans-serif;
}
h2{
    font-family: 'DM Sans', sans-serif;
}
h3{
    font-family: 'DM Sans', sans-serif;
}
h4{
    font-family: 'DM Sans', sans-serif;
}
h5{
    font-family: 'DM Sans', sans-serif;
}
h6{
    font-family: 'DM Sans', sans-serif;
}
Link{
    font-family: 'DM Sans', sans-serif;
}

p{
    font-family: 'DM Sans', sans-serif;
}
Button{
    font-family: 'DM Sans', sans-serif;
}

.titleNavbar{
    font-family: 'DM Sans', sans-serif;
    color:#000;
    font-weight: bold;
    font-size: 18PX;
    text-decoration: none;
    display: flex;
    margin: 20px;
}
.titleNavbar:hover{
    color: rgb(242, 156, 81);
}



.navmain{
    position: fixed;
    top: 0; left: 0; right: 0;
    background: #c7f465;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    padding: 20px 7%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
}
.navmain .lgnav{
    font-weight: bolder;
    font-size: 25px;
    color: #333;
    text-decoration: none;
}

.navmain .nav22 .linenav{
    list-style: none;
}
.navmain .nav22 .linenav .linenav2{
    position: relative;
    float: left;
}
.navmain .nav22 .linenav .linenav2 .txtnav{
    font-size: 18px;
    padding: 20px;
    color: #333;
    text-decoration: none;
    display: block;
    font-weight: bold;
}
.navmain .nav22 .linenav .linenav2 .txtnav:hover{
  color: #f55d2c;
}
.navmain .nav22 .linenav .linenav2 .dropnav{
    list-style: none;
    position: absolute;
    left: 0;
    width: 200px;
    background: #c7f465;
    display: none;

}
.navmain .nav22 .linenav .linenav2 .dropnav .dropnav2{
    text-decoration: none;
    font-size: 20px;
    color: #333;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, .1);
 
}
.navmain .nav22 .linenav .linenav2 .dropnav .dropnav2 .droptxtnav:hover{
    color: #f55d2c;
  }
.navmain .nav22 .linenav .linenav2 .dropnav .dropnav2 .droptxtnav{
    text-decoration: none;
    font-size: 20px;
    padding: 20px;
    color: #333;
    text-decoration: none;
    display: block;
    left: 0;
}
.navmain .nav22 .linenav .linenav2:focus-within > .dropnav,
.navmain .nav22 .linenav .linenav2:hover > .dropnav{
    display: initial;
}

#menu-bar{
    display: none;
}
.navmain label{
    font-size: 18px;
    color: #333;
    cursor: pointer;
    display: none;
    font-weight: bold;
}
@media screen and (max-width: 1008px) {
    .navmain label{ 
        display: initial;
    }
    .navmain .nav22{
        position: absolute;
        top: 100%; left: 0; right: 0;
        background: #fff;
        border-top: 1px solid rgba(0, 0, 0, .1);
        display: none;
    }
    .navmain .nav22 .linenav .linenav2 {
        width: 100%;
    }
    .navmain .nav22 .linenav .linenav2 .dropnav{
        position: relative;
        width: 100%;
    }
    .navmain .nav22 .linenav .linenav2 .dropnav .dropnav2{
        background: #eee;
    }
    #menu-bar:checked ~ .nav22{
        display: initial;
    }
  }
  .novenavi{
    display: flex;
    justify-content: flex-end;
    width: 100%;
   
  }