.slideIamge{
    background-image: url("../Images/offer-1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 300px;
    width: 500px; */
    border-radius: 20px;
    border: 10px solid #ffffff;
    margin: 10px;
}
.slideIamge1{
    background-image: url("../Images/offer-2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 300px;
    width: 500px; */
    border-radius: 20px;
    border: 10px solid #ffffff;
    margin: 10px;
}
.slideIamge2{
    background-image: url("../Images/christmas-sweets-platter.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 300px;
    width: 500px; */
    border-radius: 20px;
    border: 10px solid #ffffff;
    margin: 10px;
}
.slideIamge4{
    background-image: url("../Images/offer-4.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 300px;
    width: 500px; */
    border-radius: 20px;
    border: 10px solid #ffffff;
    margin: 10px;
}
.slideIamge5{
    background-image: url("../Images/offer-5.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 300px;
    width: 500px; */
    border-radius: 20px;
    border: 10px solid #ffffff;
    margin: 10px;
}
.tagbg{
    background: linear-gradient(transparent,rgb(5, 5, 5));
    margin-top: 30px;
    width: 100%;
    padding: 20px;
}
.btnmain{
    margin: 20px;
    width: 150px;
    background-color: #f55d2c;
    border: none;
  height: 50px;
  font-size: 18px;
}
.btnmain:hover{
    margin: 20px;
    width: 150px;
    background-color: #de734f;
    border: none;
  height: 50px;
}