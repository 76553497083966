.footertxt {
  color: #1bbcbc;
  transition: 1s;
  font-size: 15px;
  text-decoration: none;
}

.footertxt:hover {
  transform: scale(1.1);
  color: #ffffff;
}

.iconfooter {
  height: 40px;
  width: 40px;
  color: #1bbcbc;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  transition: 1s;
  margin-left: 15px;
  background-color: rgba(110, 108, 108, 0.5);
}

.iconfooter:hover {
  color: #ffffff;
  transform: scale(1.1);
}